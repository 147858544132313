@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.experience-staticTilesItems {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 15px;
    text-align: center;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding: 15px 0;
    }

    .experience-assets-staticTile {
        padding: 0 15px;

        @include media-breakpoint-down(sm) {
            padding: 10px 15px;
        }
    }
}

.static-tile {
    .static-tile__image {
        img {
            width: 100%;
            max-width: 100px;
            max-height: 100px;

            @include media-breakpoint-down(sm) {
                max-width: 70px;
                max-height: 70x;
            }
        }
    }

    .static-tile__content {
        padding: 25px 0 0;
    }

    .static-tile-content__title {
        font-family: var(--font-primary);
        font-weight: 300;
        font-size: var(--text-lg);
        color: var(--p4p-brand-text-color);
    }
}
